<template>
    <b-card>

        <!-- media -->
        <b-media no-body>
            <b-media-aside>
                <b-avatar
                    ref="previewEl"
                    :src="avatarSrc(optionsLocal.photo)"
                    :text="avatarText(optionsLocal.names)"
                    variant="light-success"
                    size="90px"
                    rounded
                />
                <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @change="onFileChange"
                    >
                    <span class="d-none d-sm-inline">Actualizar</span>
                    <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
                <b-button
                    @click="restoreAvatar"
                    variant="outline-secondary"
                    class="ml-1"
                >
                    <span class="d-none d-sm-inline">Restaurar</span>
                    <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
                <b-button
                    @click="removeAvatar"
                    variant="outline-secondary"
                    class="ml-1"
                >
                    <span class="d-none d-sm-inline">Remover</span>
                    <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
                <b-card-text class="mt-1">JPG, JPEG o PNG permitidos.</b-card-text>
            </b-media-body>
        </b-media>
        <!--/ media -->

        <!-- form -->
        <b-form class="mt-2">
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        label="Nombre(s)"
                        label-for="account-name">
                        <b-form-input
                            v-model="optionsLocal.names"
                            name="names"
                            placeholder="Nombre(s)"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Apellido(s)"
                        label-for="account-username"
                    >
                        <b-form-input
                            v-model="optionsLocal.surnames"
                            placeholder="Apellido(s)"
                            name="surnames"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Correo electrónico"
                        label-for="account-e-mail">
                        <b-form-input
                            v-model="optionsLocal.email"
                            name="email"
                            placeholder="Correo electrónico"
                        />

                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Teléfono"
                        label-for="account-phone">
                        <b-form-input
                            v-model="optionsLocal.phone"
                            name="phone"
                            placeholder="Teléfono"
                        />

                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-button
                        @click="updateGeneralInformation"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1">
                        Guardar cambios
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BFormFile, BAvatar, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg } from 'bootstrap-vue';
    import Ripple from 'vue-ripple-directive';
    import { avatarText } from '@core/utils/filter';
    import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
    import { ref } from '@vue/composition-api';
    import axios from 'axios';

    export default {
        components: {
            BButton,
            BForm,
            BImg,
            BFormFile,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BAlert,
            BCard,
            BCardText,
            BMedia,
            BMediaAside,
            BMediaBody,
            BLink,
            BAvatar,
        },
        directives: {
            Ripple,
        },
        props: {
            generalData: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                optionsLocal: this.generalData,
                profileFile: null,
            }
        },
        methods: {
            avatarSrc (img){
                if (img){
                    if (img.includes('http')){
                        return img;
                    } else {
                        if (process.env.VUE_APP_ENVIROMENT == 'development'){
                            return require(`@/assets/images/modules/profiles/${img}`);
                        } else {
                            return `${process.env.VUE_APP_FILES}/modules/profiles/${img}`;
                        }   
                    }
                } else {
                    return null;
                }
            },
            resetForm() {
                this.optionsLocal = this.generalData
            },
        },
        setup(props) {

            // InitPhoto
            let initPhoto = props.generalData.photo;

            // Use toast
            const toast = useToast();

            const blankData = {
                photo: null,
                initPhoto
            };

            // ? Demo Purpose => Update image on click of update
            const refInputEl = ref(null);
            const previewEl = ref(null);

            const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
                // eslint-disable-next-line no-param-reassign
                props.generalData.photo = base64;
            });

            const onFileChange = (e) => {
                const file = e.target.files[0];
                props.generalData.changePhoto = 1;
                if (file) {
                    props.generalData.photo = URL.createObjectURL(file);
                    blankData.photo = file;
                } else {
                    props.generalData.photo = null;
                    blankData.photo = null;
                }
            };

            const updateGeneralInformation = () => {
                let generalData = {
                    ...props.generalData,
                    ...blankData
                };

                let formData = new FormData();

                formData.append('id', generalData.id);
                formData.append('names', generalData.names);
                formData.append('surnames', generalData.surnames);
                formData.append("photo", generalData.photo);
                formData.append("changePhoto", generalData.changePhoto);
                formData.append("initPhoto", generalData.initPhoto);
                formData.append("email", generalData.email);
                formData.append("phone", generalData.phone);

                axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/access/update/general-information`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then( (response) => {

                    props.generalData.photo = response.data.data.photo;
                    blankData.initPhoto = response.data.data.photo;
                    props.generalData.changePhoto = 0;

                    let localStorageUserData = JSON.parse(localStorage.getItem('userData'));
                    let data = {
                        ...localStorageUserData,
                        ...response.data.data
                    };
                    localStorage.setItem('userData', JSON.stringify(data));
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar tu información general';
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            };

            const restoreAvatar = () => {
                props.generalData.photo = initPhoto;
                blankData.photo = null;
                props.generalData.changePhoto = 0;
            };

            const removeAvatar = () => {
                props.generalData.photo = null;
                blankData.photo = null;
                props.generalData.changePhoto = 1;
            };

            return {
                refInputEl,
                previewEl,
                inputImageRenderer,

                avatarText,
                restoreAvatar,
                removeAvatar,
                onFileChange,
                updateGeneralInformation
            }
        }
    }

</script>
